<template>
  <CCard
    class="m-0 border-0"
  >
    <CCardBody>
      <gmap-map
        :center="center"
        :zoom="zoom"
        style="width: 100%; min-height: 400px; margin: 0px;"
      >
        <gmap-info-window
          :options="{
            pixelOffset: {
              width: 0,
              height: -35
            }
          }"
          :position="infoWindow && infoWindow.position"
          :opened="!!infoWindow"
          @closeclick="infoWindow=null"
        >
          <span
            v-if="infoWindow"
            @click="$router.push(infoWindow.link)"
          >
            {{ infoWindow.content }}
          </span>
        </gmap-info-window>
        <gmap-marker
          v-for="(item, key) in markers"
          :key="key"
          :position="getPosition(item)"
          :clickable="true"
          @click="toggleInfoWindow(item, key)"
        />
      </gmap-map>
    </CCardBody>
  </CCard>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'GoogleMap',
  props: {
    places: {
      type: Array,
      required: true,
    },
    zoom: {
      type: Number,
      required: false,
      default: 8,
    },
  },
  data() {
    return {
      center: {
        lat: 50.0621559,
        lng: 14.4373919,
      },
      currentPlace: {},
      infoWindow: null,
    };
  },
  computed: {
    markers() {
      return _.map(this.places, (place) => ({
        position: {
          lat: place.longitude,
          lng: place.latitude,
        },
        place,
      }));
    },
  },
  watch: {
    markers: {
      immediate: true,
      handler() {
        this.geolocate();
      },
    },
  },
  methods: {
    setPlace(place) {
      this.currentPlace = place;
    },
    getPosition(marker) {
      return {
        lat: parseFloat(marker.position.lat),
        lng: parseFloat(marker.position.lng),
      };
    },
    geolocate() {
      this.center = {
        lat: _.get(this.markers, [0, 'position', 'lat'], 0),
        lng: _.get(this.markers, [0, 'position', 'lng'], 0),
      };
    },
    toggleInfoWindow(marker, idx) {
      this.infoWindow = {
        position: marker.position,
        content: `${marker.place.name}`,
        link: { name: 'Space', params: { id: marker.place.id } },
      };
      console.log(marker);
    },
  },
};
</script>
