import _ from 'lodash';
import Vue from 'vue';
import { validate } from 'vee-validate';

type FieldType = 'string' | 'number' | 'boolean' | 'array'
type FieldDataType<T extends FieldType> =
  T extends 'string' ? string :
  T extends 'number' ? number :
  T extends 'boolean' ? boolean :
  T extends 'array' ? any[] :
  never

interface FieldDef {
  type: FieldType;
  format?: string;
  validations?: {
    [key: string]: any;
  };
}

interface ModelDefOptions {
  [fieldName: string]: FieldDef;
}

interface Clonable {
  clone(): this;
}

interface Validatable {
  $validate(): Promise<import('vee-validate/dist/types/types').ValidationResult>;
  $isValid(): Promise<boolean>;
}

type ModelData<T extends ModelDefOptions> = {
  [fieldName in keyof T]: FieldDataType<T[fieldName]['type']>
}

type ModelDef<T extends ModelDefOptions> = ModelData<T> & {
  $data: ModelData<T>;
  _data: ModelData<T>;
  $fields: T;
  $validations: {
    [fieldName in keyof T]: T[fieldName]['validations'];
  };
} & Clonable & Validatable;

export default function Model<T extends ModelDefOptions>(definition: T):
  (new (data: Partial<ModelData<T>>) => ModelDef<T>) {
  function Model(data = {}) {
    this._data = Vue.observable(data);
  }

  Model.prototype = {
    get $data() {
      return this._data;
    },
    get $fields() {
      return definition;
    },
    get $validations() {
      return _.mapValues(this.$fields, 'validations');
    },
    async $isValid() {
      return _.every(_.values(await this.$validate()), (x) => x.valid);
    },
    async $validate() {
      const validationResults = await Promise.all(_.map(
        Object.keys(this.$validations),
        async (key) => ({ [key]: await validate(this[key], this.$validations[key]) }),
      ));
      return _.merge({}, ...validationResults);
    },
  };

  for (const key of Object.keys(definition)) {
    Object.defineProperty(Model.prototype, key, {
      get() {
        return _.get(this._data, key);
      },
      set(val) {
        Vue.set(this._data, key, val);
      },
    });
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  return Model;
}
