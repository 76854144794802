<template>
  <div>
    <CCard class="border-0 background-0 mb-0 pb-2">
      <CButton
        v-if="$acl.check('isAdmin')"
        color="primary"
        class="ml-auto"
        :to="'/teams-add'"
      >
        Add new
      </CButton>
    </CCard>
    <TeamsTable
      :current-teams="teams"
    />
  </div>
</template>

<script>
import TeamsTable from '@/components/Team/TeamsTable';
import _ from 'lodash';

export default {
  components: {
    TeamsTable,
  },
  computed: {
    teams() {
      return _.map(this.$store.state.teams.teams);
    },
  },
  created() {
    this.$store.dispatch('teams/loadTeams');
  },
};
</script>
