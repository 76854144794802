import Vue from 'vue';
import Model from '../Model';

export default class Team extends Model({
  id: {
    type: 'string',
  },
  name: {
    type: 'string',
    validations: {
      required: true,
    },
  },
  description: {
    type: 'string',
    validations: {
      min: 10,
      required: true,
    },
  },
}) {

}
