import API from './api.service';

class UploadFilesService {
  public formData;

  async uploadFile(file) {
    this.formData = file;
    return API.post('/v1/file-upload', this.formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}

export default new UploadFilesService();
