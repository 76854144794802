<template>
  <div>
    <ValidationObserver ref="validator">
      <form
        @submit.prevent="checkForm"
      >
        <CCard>
          <CCardBody>
            <CRow>
              <CCol sm="12">
                <ValidationProvider
                  v-slot="{errors, valid, validated}"
                  :rules="team.$validations.name"
                  name="Name"
                >
                  <CInput
                    v-model="team.name"
                    name="name"
                    label="Name"
                    placeholder="Development"
                    :is-valid="!validated ? null : valid"
                    :invalid-feedback="errors[0]"
                  />
                </ValidationProvider>
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="12">
                <ValidationProvider
                  v-slot="{errors, valid, validated}"
                  :rules="team.$validations.description"
                  name="Description"
                >
                  <CTextarea
                    v-model="team.description"
                    label="Description"
                    placeholder="Description of team..."
                    :is-valid="!validated ? null : valid"
                    :invalid-feedback="errors[0]"
                  />
                </ValidationProvider>
              </CCol>
            </CRow>
            <CRow>
              <CCol
                sm="12"
                align="right"
              >
                <CButton
                  color="primary"
                  type="submit"
                  class="ml-auto mb-2"
                  :disabled="sending"
                >
                  <CSpinner
                    v-if="sending"
                    color="info"
                    size="sm"
                  />
                  Save new team
                </CButton>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import Team from '@/model/team/team';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import store from '@/store';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      team: new Team(),
      sending: false,
    };
  },
  async beforeRouteEnter(to, from, next) {
    if (to.params.id) {
      const team = store.state.teams.teams[to.params.id];
      console.log(team);
      next((vm) => {
        vm.team = team;
      });
    } else {
      next();
    }
  },
  methods: {
    async checkForm() {
      try {
        this.sending = true;
        const valid = await this.$refs.validator.validate();
        if (valid) {
          await this.$store.dispatch('teams/saveTeam', this.team);
          this.$router.push('/teams');
          this.$store.dispatch('alert/success', 'success');
        }
      } catch (error) {
        this.$store.dispatch('alert/error', 'error');
      } finally {
        this.sending = false;
      }
    },
  },
};
</script>
