<template>
  <CDropdown
    in-nav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="mt-2 pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar">
          <img
            :src="user | getAvatar"
            class="c-avatar-img"
          >
        </div>
        <div class="ml-2 text-dark">
          {{ user.name }}
        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader
      tag="div"
      class="text-center"
      color="light"
    >
      <strong>Account</strong>
    </CDropdownHeader>
    <CDropdownItem :to="{ name: 'My Profile'}">
      <CIcon
        name="cil-user"
      />
      Profile
    </CDropdownItem>
    <CDropdownItem to="/login">
      <CIcon
        name="cil-lock-locked"
      />
      Logout
    </CDropdownItem>
  </CDropdown>
</template>

<script>
import md5 from 'md5';

export default {
  name: 'HeaderAccountDropdown',
  filters: {
    getAvatar(user) {
      if (!user.avatar && user.email) {
        return `https://www.gravatar.com/avatar/${md5(user.email)}`;
      }
      return user.avatar;
    },
  },
  data() {
    return {
      itemsCount: 42,
    };
  },
  computed: {
    user() {
      return this.$store.state.persons.me;
    },
  },
};
</script>
