import Vue from 'vue';
import VueLodash from 'vue-lodash';
import lodash from 'lodash';
import moment from 'vue-moment';
import VueTruncate from 'vue-truncate-filter';
import CoreuiVue from '@coreui/vue';
import * as VueGoogleMaps from 'vue2-google-maps';
import { BootstrapVue } from 'bootstrap-vue';
import '@/helpers/veeValidate';
import '@/helpers/filters';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '@/assets/scss/settings.scss';
import '@/assets/scss/base.scss';
import icons from '@/assets/icons/icons.ts';
import store from './store';
import router from './router';
import acl from './helpers/acl';
import App from './App.vue';

Vue.use(VueLodash, { name: 'custom', lodash });
Vue.use(VueTruncate);
Vue.use(BootstrapVue);
Vue.use(CoreuiVue);
Vue.use(moment);
Vue.config.productionTip = false;
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAevn5cvTGXwcV096YAwmI2p0XfpbjslKQ',
    libraries: 'places', // necessary for places input
  },
});
new Vue({
  store,
  acl,
  router,
  icons,
  moment,
  render: (h) => h(App),
} as Vue.ComponentOptions<Vue>).$mount('#app');
