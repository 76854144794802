<template>
  <div class="c-app">
    <TheSidebar />
    <CWrapper>
      <TheHeader />
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <div
              v-if="alert.message"
              :class="`alert ${alert.type}`"
            >
              {{ alert.message }}
            </div>
            <transition
              name="fade"
              mode="out-in"
            >
              <router-view />
            </transition>
          </CContainer>
        </main>
        <TheFooter />
      </div>
    </CWrapper>
  </div>
</template>

<script>
import TheSidebar from './Sidebar';
import TheHeader from './Header';
import TheFooter from './Footer';

export default {
  name: 'Container',
  components: {
    TheSidebar,
    TheHeader,
    TheFooter,
  },
  computed: {
    alert() {
      return this.$store.state.alert;
    },
  },
};
</script>
