<template>
  <div>
    <TacosTable
      v-if="tacos.length"
      :tacos="tacos"
    />
  </div>
</template>

<script>
import TacosTable from '@/components/TacosTable';
import _ from 'lodash';

export default {
  name: 'App',
  components: {
    TacosTable,
  },
  computed: {
    tacos() {
      return _.map(this.$store.state.tacos.tacos);
    },
  },
  created() {
    this.$store.dispatch('tacos/loadTacos');
  },
};
</script>
