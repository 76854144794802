<template>
  <CCard>
    <CCardBody>
      <CDataTable
        :items="tacos"
        :fields="fields"
        table-filter
        items-per-page-select
        :items-per-page="5"
        hover
        pagination
        sorter
        class="justify-content-center align-self-center"
      >
        <template #username="{item}">
          <td>
            <b-avatar
              :if="item.avatar"
              :src="item.avatar"
              size="3rem"
              class="mr-2"
            />
            {{ item.username }}
          </td>
        </template>
      </CDataTable>
    </CCardBody>
  </CCard>
</template>
<script>
export default {
  props: {
    tacos: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      details: [],
      fields: [
        {
          key: 'username',
          label: 'User',
        },
        { key: 'team_name' },
        {
          key: 'sum',
          _style: 'width:10%;',
        },
      ],
    };
  },
};
</script>
