import Model from '../Model';

export default class Me extends Model({
  id: {
    type: 'string',
  },
  name: {
    type: 'string',
    validations: {
      required: true,
    },
  },
  position: {
    type: 'string',
    validations: {
      required: true,
    },
  },
  birth_date: {
    type: 'string',
    validations: {
      required: true,
    },
  },
  phone_number: {
    type: 'string',
    validations: {
      required: true,
    },
  },
  email: {
    type: 'string',
    validations: {
      required: true,
    },
  },
  avatar: {
    type: 'string',
    validations: {
      required: true,
    },
  },
  long_description: {
    type: 'string',
    validations: {
      required: true,
      min: 100,
    },
  },
}) {
}
