<template>
  <CFooter :fixed="false">
    <div>
      <a
        href="https://spaceflow.io"
        target="_blank"
      >Spaceflow</a>
      <span class="ml-1">&copy; {{ new Date().getFullYear() }}</span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'Footer',
};
</script>
