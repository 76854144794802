import API from '@/common/api.service';
import Link from '@/model/links/link';
import _ from 'lodash';

export const links = {
  namespaced: true,
  state: {
    links: [],
    knowledge: [],
  },
  actions: {
    async deleteLink({ commit }, id) {
      const links = await API.delete(`/v1/links/delete/${id}`);
      commit('DELETE_LINK', id);
      return links.data;
    },
    async loadLinks({ commit }) {
      const links = await API.get('/v1/links');
      commit('SAVE_LINKS', links.data);
      return links.data;
    },
    async loadLink({ commit }, id) {
      const link = await API.get(`/v1/links/${id}`);
      return link.data;
    },
    async loadKnowledge({ commit }) {
      const links = await API.get('/v1/links-for-user/');
      commit('SAVE_KNOWLEDGE', links.data);
      return links.data;
    },
    async saveLink({ commit }, data: Link) {
      if (data.id) {
        return new Link((await API.put(`/v1/links/edit/${data.id}`, data)).data);
      }
      return new Link((await API.post('/v1/links/create', data.$data)).data);
    },
  },
  mutations: {
    SAVE_LINKS(state: any, teams) {
      state.links = _.mapKeys(teams, 'id');
    },
    SAVE_KNOWLEDGE(state: any, konwledge) {
      state.knowledge = _.mapKeys(konwledge, 'id');
    },
    DELETE_LINK(state: any, id) {
      delete state.links[id];
    },
  },
};
