<template>
  <div>
    <CRow align="center">
      <CCol
        lg="4"
        md="6"
        sm="12"
      >
        <CCard>
          <CCardBody>
            <CRow>
              <CCol sm="12">
                <img
                  v-if="currentSpace.logo"
                  :src="`${currentSpace.logo}`"
                  class="mb-2 w-100"
                >
                <h2>{{ currentSpace.name }}</h2>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
        <CCard>
          <CCardBody>
            <CRow>
              <CCol sm="12">
                <SpaceQRCode
                  :space="currentSpace"
                  style="max-width: 250px"
                />
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol
        lg="8"
        md="6"
        sm="12"
      >
        <CCard>
          <CCardBody
            class="p-0"
          >
            <CDataTable
              :items="[currentSpace]"
              :fields="fields"
              class="w-100"
            >
              <template #buildingType="{item}">
                <td>
                  {{ item.buildingType | spaceType }}
                </td>
              </template>
            </CDataTable>
          </CCardBody>
        </CCard>
        <CCard
          class="p-0"
        >
          <google-map
            :places="[currentSpace]"
            :zoom="12"
          />
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import GoogleMap from '@/components/GoogleMap';
import SpaceQRCode from '@/components/Spaces/SpaceQRCode';

export default {
  components: {
    GoogleMap,
    SpaceQRCode,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'portfolio',
          label: 'Portfolio',
        },
        {
          key: 'country',
          label: 'Country',
        },
        {
          key: 'accessCode',
          label: 'Access code',
        },
        {
          key: 'buildingType',
          label: 'Building type',
        },
        {
          key: 'userCount',
          label: 'Users count',
        },
        {
          key: 'area',
          label: 'Area (m²)',
        },
      ],
    };
  },
  computed: {
    currentSpace() {
      return this.$store.state.spaces[this.id];
    },
  },
};
</script>
