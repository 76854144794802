<template>
  <div>
    <CRow
      class="col-12 mr-0 pr-0 mb-3"
    >
      <CButton
        color="secondary"
        :to="'/knowledge'"
      > Back
      </CButton>
      <CButton
        v-if="$acl.check('isAdmin')"
        color="primary"
        class="ml-auto mr-0"
        :to="'/links-add'"
      >
        Add new
      </CButton>
    </CRow>
    <LinksTable
      :current-links="alllinks"
    />
  </div>
</template>

<script>
import LinksTable from '@/components/Links/LinksTable';
import _ from 'lodash';

export default {
  components: {
    LinksTable,
  },
  computed: {
    alllinks() {
      return _.map(this.$store.state.links.links);
    },
  },
  created() {
    this.$store.dispatch('links/loadLinks');
  },
};
</script>
