<template>
  <transition name="fade">
    <div>
      <CCard class="border-0 background-0 mb-0 pb-2">
        <CButton
          v-if="$acl.check('isAdmin')"
          color="primary"
          class="ml-auto"
          :to="'/add-person'"
        >
          Add new
        </CButton>
      </CCard>
      <PeopleTable
        :current-people="persons"
      />
    </div>
  </transition>
</template>

<script>
import PeopleTable from '@/components/Persons/PeopleTable';
import _ from 'lodash';

export default {
  name: 'App',
  components: {
    PeopleTable,
  },
  data() {
    return {
      index: 0,
    };
  },
  computed: {
    persons() {
      return _.map(this.$store.state.persons.persons, (person) => person._data);
    },
  },
  created() {
    this.$store.dispatch('persons/loadPersons');
  },
};
</script>
