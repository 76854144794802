import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import VueAxios from 'vue-axios';
import _ from 'lodash';
import API from '@/common/api.service';
import UploadFiles from '@/common/upload.service';
import { alert } from './alert.module';
import { authentication } from './auth.module';
import { teams } from './teams.module';
import { links } from './links.module';
import { tacos } from './tacos.module';
import { persons } from './persons.module';

Vue.use(Vuex);
Vue.use(VueAxios, axios);

const state = {
  spaces: [],
  user: [],
  selectedPlatform: ['all'],
  sidebarShow: 'responsive',
  sidebarMinimize: true,
};

export default new Vuex.Store({
  state,
  actions: {
    login({ commit }) {
      return API.get('/v1/authentication/login').then((result) => {
        commit('SAVE_USER', result.data);
      }).catch((error) => {
        throw new Error(`API ${error}`);
      });
    },
    async loadSpaces({ commit }) {
      const result = await API.get('/v1/building/list');
      _.forEach(result.data, (item) => {
        if (item.accessCode) {
          if (item.accessCode.toString().length < 2) {
            item.accessCode = `000${item.accessCode}`;
          } else if (item.accessCode.toString().length < 3) {
            item.accessCode = `00${item.araccessCodeea}`;
          } else if (item.accessCode.toString().length < 4) {
            item.accessCode = `0${item.accessCode}`;
          }
        }
      });
      commit('SAVE_SPACES', result.data);
      return result.data;
    },
    async uploadImage({ commit }, file) {
      const result = await UploadFiles.uploadFile(file);
      return result.data;
    },
    toggleSidebarDesktop({ commit }) {
      const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow);
      const sidebar = sidebarOpened ? false : 'responsive';
      commit('SET_SIDEBAR', sidebar);
    },
    toggleSidebarMobile({ commit }) {
      const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow);
      const sidebar = sidebarClosed ? true : 'responsive';
      commit('SET_SIDEBAR', sidebar);
    },
    toggleSidebarMinimize({ commit }) {
      commit('SET_SIDEBAR_MINIMIZE');
    },
  },
  mutations: {
    SAVE_USER(state: any, persons) {
      state.user = persons;
    },
    SAVE_SPACES(state: any, spaces) {
      state.spaces = _.mapKeys(spaces, 'id');
    },
    SET_SIDEBAR(state: any, sidebar: any) {
      state.sidebarShow = !state.sidebarShow;
    },
    SET_SIDEBAR_MINIMIZE(state: any) {
      state.sidebarMinimize = !state.sidebarMinimize;
    },
  },
  modules: {
    teams,
    alert,
    authentication,
    links,
    persons,
    tacos,
  },
});
