var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"validator"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.checkForm($event)}}},[_c('CCard',[_c('CCardBody',[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('ValidationProvider',{attrs:{"rules":_vm.link.$validations.name,"name":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var validated = ref.validated;
return [_c('CInput',{attrs:{"name":"name","label":"Name","placeholder":"Link name","is-valid":!validated ? null : valid,"invalid-feedback":errors[0]},model:{value:(_vm.link.name),callback:function ($$v) {_vm.$set(_vm.link, "name", $$v)},expression:"link.name"}})]}}])})],1)],1),_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('ValidationProvider',{attrs:{"rules":_vm.link.$validations.web_link,"name":"Link"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var validated = ref.validated;
return [_c('CInput',{attrs:{"label":"Link","type":"url","placeholder":"https://spaceflow.io/document.pdf","is-valid":!validated ? null : valid,"invalid-feedback":errors[0]},model:{value:(_vm.link.web_link),callback:function ($$v) {_vm.$set(_vm.link, "web_link", $$v)},expression:"link.web_link"}})]}}])})],1)],1),_c('CRow',[_c('CCol',{staticClass:"p-3",attrs:{"sm":"12"}},[_c('ValidationProvider',{attrs:{"rules":_vm.link.$validations.image_url,"name":"Image"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var passed = ref.passed;
var failed = ref.failed;
return [_c('label',{attrs:{"sm":"12"}},[_vm._v("Upload image")]),_c('avatar-cropper',{class:{
                    'is-valid': passed,
                    'is-invalid': failed
                  },attrs:{"trigger":"#pick-avatar"},model:{value:(_vm.link.image_url),callback:function ($$v) {_vm.$set(_vm.link, "image_url", $$v)},expression:"link.image_url"}}),_c('span',{staticClass:"invalid invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('ValidationProvider',{attrs:{"rules":_vm.link.$validations.team_uuid,"name":"Team"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
                  var valid = ref.valid;
                  var validated = ref.validated;
return [_c('CSelect',{attrs:{"value":_vm.link.team_uuid,"label":"Team","options":_vm.teamOptions,"invalid-feedback":errors[0],"is-valid":!validated ? null : valid},on:{"change":_vm.onChangeTeam}})]}}])})],1)],1),_c('CRow',[_c('CCol',{attrs:{"sm":"12","align":"right"}},[_c('CButton',{staticClass:"ml-auto mb-2",attrs:{"color":"primary","type":"submit","disabled":_vm.sending}},[(_vm.sending)?_c('CSpinner',{attrs:{"color":"info","size":"sm"}}):_vm._e(),(_vm.id)?_c('div',[_vm._v(" Edit ")]):_c('div',[_vm._v(" Create Link ")])],1)],1)],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }