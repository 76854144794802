<template>
  <div>
    <ValidationObserver ref="validator">
      <form
        @submit.prevent="checkForm"
      >
        <CCard>
          <CCardBody>
            <CRow>
              <CCol sm="12">
                <ValidationProvider
                  v-slot="{errors, valid, validated}"
                  :rules="link.$validations.name"
                  name="Name"
                >
                  <CInput
                    v-model="link.name"
                    name="name"
                    label="Name"
                    placeholder="Link name"
                    :is-valid="!validated ? null : valid"
                    :invalid-feedback="errors[0]"
                  />
                </ValidationProvider>
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="12">
                <ValidationProvider
                  v-slot="{errors, valid, validated}"
                  :rules="link.$validations.web_link"
                  name="Link"
                >
                  <CInput
                    v-model="link.web_link"
                    label="Link"
                    type="url"
                    placeholder="https://spaceflow.io/document.pdf"
                    :is-valid="!validated ? null : valid"
                    :invalid-feedback="errors[0]"
                  />
                </ValidationProvider>
              </CCol>
            </CRow>
            <CRow>
              <CCol
                sm="12"
                class="p-3"
              >
                <ValidationProvider
                  v-slot="{errors, passed, failed}"
                  :rules="link.$validations.image_url"
                  name="Image"
                >
                  <label sm="12">Upload image</label>
                  <avatar-cropper
                    v-model="link.image_url"
                    trigger="#pick-avatar"
                    :class="{
                      'is-valid': passed,
                      'is-invalid': failed
                    }"
                  />
                  <span class="invalid invalid-feedback">{{ errors[0] }}</span>
                </ValidationProvider>
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="12">
                <ValidationProvider
                  v-slot="{errors, valid, validated}"
                  :rules="link.$validations.team_uuid"
                  name="Team"
                >
                  <CSelect
                    :value="link.team_uuid"
                    label="Team"
                    :options="teamOptions"
                    :invalid-feedback="errors[0]"
                    :is-valid="!validated ? null : valid"
                    @change="onChangeTeam"
                  />
                </ValidationProvider>
              </CCol>
            </CRow>
            <CRow>
              <CCol
                sm="12"
                align="right"
              >
                <CButton
                  color="primary"
                  type="submit"
                  class="ml-auto mb-2"
                  :disabled="sending"
                >
                  <CSpinner
                    v-if="sending"
                    color="info"
                    size="sm"
                  />
                  <div
                    v-if="id"
                  >
                    Edit
                  </div>
                  <div
                    v-else
                  >
                    Create Link
                  </div>
                </CButton>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import Link from '@/model/links/link';
import store from '@/store';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import _ from 'lodash';
import AvatarCropper from '@/components/AvatarCropper';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    AvatarCropper,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      link: new Link(),
      sending: false,
    };
  },
  async beforeRouteEnter(to, from, next) {
    if (to.params.id) {
      const link = store.state.links.links[to.params.id];
      next((vm) => {
        vm.link = link;
      });
    } else {
      next();
    }
  },
  computed: {
    teamOptions() {
      return _.map(this.$store.state.teams.teams, (team) => ({
        value: team.id,
        label: team.name,
      }));
    },
  },
  watch: {
    link: {
      handler() {
        if (_.isNil(this.link.team_uuid)) {
          this.link.team_uuid = this.teamOptions[0].value;
        }
        if (!_.isNil(this.link.team.id)) {
          this.link.team_uuid = this.link.team.id;
        }
      },
      immediate: true,
    },
  },
  methods: {
    onChangeTeam(event) {
      this.link.team_uuid = event.target.value;
    },
    async checkForm() {
      try {
        this.sending = true;
        const valid = await this.$refs.validator.validate();
        if (valid) {
          await this.$store.dispatch('links/saveLink', this.link);
          this.$router.push('/links');
          this.$store.dispatch('alert/success', 'success');
        }
      } catch (error) {
        this.$store.dispatch('alert/error', 'error');
      } finally {
        this.sending = false;
      }
    },
  },
};
</script>
