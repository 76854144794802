import Vue from 'vue';
import _ from 'lodash';
import { AclInstaller, AclCreate, AclRule } from 'vue-acl';
import { UserRoles } from '@/helpers';
import router from '../router';
import store from '../store';

Vue.use(AclInstaller);

export default new AclCreate({
  initial: 'unauthorized',
  notfound: {
    path: '/login',
    forwardQueryParams: true,
  },
  router,
  acceptLocalRules: true,
  globalRules: {
    isAdmin: new AclRule(UserRoles.admin).generate(),
    isPublic: new AclRule(UserRoles.user).or(UserRoles.admin).generate(),
  },
  middleware: async (acl) => {
    try {
      if ((store.state as any).authentication.token) {
        await store.dispatch('persons/loadMe');
        if (_.includes((store.state as any).persons.me.roles, UserRoles.admin)) {
          acl.change(UserRoles.admin);
        } else {
          acl.change(UserRoles.user);
        }
      }
    } catch (e) {
      acl.change('unauthorized');
    }
  },
});
