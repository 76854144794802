<template>
  <div>
    <SpacesTable
      v-if="spaces.length"
      :spaces="spaces"
    />
  </div>
</template>

<script>
import SpacesTable from '@/components/SpacesTable';
import _ from 'lodash';

export default {
  name: 'App',
  components: {
    SpacesTable,
  },
  computed: {
    spaces() {
      return _.map(this.$store.state.spaces);
    },
  },
  created() {
    this.$store.dispatch('loadSpaces');
  },
};
</script>
