var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"validator"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.checkForm($event)}}},[_c('CCard',[_c('CCardBody',[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('ValidationProvider',{attrs:{"rules":_vm.team.$validations.name,"name":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var validated = ref.validated;
return [_c('CInput',{attrs:{"name":"name","label":"Name","placeholder":"Development","is-valid":!validated ? null : valid,"invalid-feedback":errors[0]},model:{value:(_vm.team.name),callback:function ($$v) {_vm.$set(_vm.team, "name", $$v)},expression:"team.name"}})]}}])})],1)],1),_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('ValidationProvider',{attrs:{"rules":_vm.team.$validations.description,"name":"Description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var validated = ref.validated;
return [_c('CTextarea',{attrs:{"label":"Description","placeholder":"Description of team...","is-valid":!validated ? null : valid,"invalid-feedback":errors[0]},model:{value:(_vm.team.description),callback:function ($$v) {_vm.$set(_vm.team, "description", $$v)},expression:"team.description"}})]}}])})],1)],1),_c('CRow',[_c('CCol',{attrs:{"sm":"12","align":"right"}},[_c('CButton',{staticClass:"ml-auto mb-2",attrs:{"color":"primary","type":"submit","disabled":_vm.sending}},[(_vm.sending)?_c('CSpinner',{attrs:{"color":"info","size":"sm"}}):_vm._e(),_vm._v(" Save new team ")],1)],1)],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }