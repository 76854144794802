<template>
  <div>
    <CRow align="center">
      <CCol>
        <CCard>
          <CCardBody>
            <CRow>
              <CCol sm="12">
                <a
                  :href="currentLink.webLink"
                  target="_blank"
                >
                  {{ currentLink.name }}
                </a>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    currentLink() {
      return this.$store.state.links.links[this.id];
    },
  },
};
</script>
