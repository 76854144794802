<template>
  <div class="form-group">
    <label
      v-if="label !== null"
      :for="`form-${_uid}`"
    >
      {{ label }}
    </label>
    <div class="mb-1">
      <div class="tiptap-menubar">
        <button
          class="tiptap-menubar__button"
        >
          <CSpinner size="sm" />
        </button>
      </div>
    </div>
    <!-- eslint-disable vue/no-v-html -->
    <div
      class="form-control tiptab__editor"
      :class="{
        'is-invalid': isValid === false,
      }"
      v-html="currentValue"
    />
    <!-- eslint-enable vue/no-v-html -->
    <div
      v-if="invalidFeedback"
      class="invalid-feedback"
    >
      {{ invalidFeedback }}
    </div>
  </div>
</template>
<script>
import sanitizeHtml from 'sanitize-html';

export default {
  props: {
    label: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: '',
    },
    invalidFeedback: {
      type: String,
      default: null,
    },
    isValid: {
      type: Boolean,
      default: null,
    },
  },
  computed: {
    currentValue() {
      // default config https://www.npmjs.com/package/sanitize-html#default-options
      return sanitizeHtml(this.value);
    },
  },
};
</script>
