import Vue from 'vue';

Vue.filter('spaceType', (type) => {
  const spaceTypes = [
    'Office',
    'Residence',
    'Community',
    'hotel',
    'coworking',
    'campus',
    'studentHousing',
    'multipurpose', 'event',
    'coliving',
    'privateClub'];
  return spaceTypes[type];
});
