import API from '@/common/api.service';

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('user');
}

async function login(code) {
  const response = await API.post('/v1/authentication/login', { token: code });
  return response.data.jwtToken;
}

export const userService = {
  login,
  logout,
};
