import API from '@/common/api.service';
import Team from '@/model/team/team';
import _ from 'lodash';

export const teams = {
  namespaced: true,
  state: {
    teams: [],
  },
  actions: {
    async deleteTeam({ commit }, id) {
      const team = await API.delete(`/v1/team/delete/${id}`);
      commit('DELETE_TEAM', id);
      return team.data;
    },
    async loadTeams({ commit }) {
      const teams = await API.get('/v1/teams');
      commit('SAVE_TEAMS', teams.data);
      return teams.data;
    },
    async loadTeam({ commit }, id) {
      const team = await API.get(`/v1/team/${id}`);
      return team.data;
    },
    async saveTeam({ commit }, data: Team) {
      if (data.id) {
        return new Team((await API.put(`/v1/team/edit/${data.id}`, data)).data);
      }
      return new Team((await API.post('/v1/team/create', data.$data)).data);
    },
  },
  mutations: {
    SAVE_TEAMS(state: any, teams) {
      state.teams = _.mapKeys(teams, 'id');
    },
    DELETE_TEAM(state: any, id) {
      delete state.teams[id];
    },
  },
};
