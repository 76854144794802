<template>
  <div>
    <CCard class="border-0 background-0 mb-0 pb-2">
      <CRow
        class="ml-auto mr-2"
      >
        <CButton
          v-if="$acl.check('isAdmin')"
          color="primary"
          class="mr-2"
          :to="'/links-add'"
        >
          Add new
        </CButton>
        <CButton
          v-if="$acl.check('isAdmin')"
          color="secondary"
          :to="'/links'"
        >
          Manage all links
        </CButton>
      </CRow>
    </CCard>
    <CTabs
      variant="tabs"
      :active-tab="0"
    >
      <CTab
        title="Links by team"
      >
        <CRow
          v-for="linkByTeams of linksByTeams"
          :key="linkByTeams.id"
          class="flex-row pt-4"
        >
          <CCol
            class="col-12"
          >
            <h2
              class="mb-3 ml-2"
            >
              {{linkByTeams[0].team.title}}
            </h2>
          </CCol>
          <CCol
            v-for="link of linkByTeams"
            :key="link.id"
            align="left"
            sm="4"
            md="3"
          >
            <a
              :href="link.web_link"
            >
              <CCard
                bodyWrapper
                :router="link.web_link"
                sm="4"
              >
                <CRow>
                  <CCol
                    sm="4"
                    align="center"
                  >
                    <b-avatar
                      id="avatar"
                      v-if="link.image_url[0] !== 'f'"
                      :src="link.image_url"
                      size="3.5rem"
                      style="min-width:50px"
                    />
                    <b-avatar
                      id="avatar"
                      v-else-if="link.image_url[0] === 'f'"
                      size="3.5rem"
                      style="min-width:50px"
                    >
                      <font-awesome-icon
                        :icon="link.image_url.substring(3, link.image_url.length)"
                        size="2x"
                        class="fawesome"
                      />
                    </b-avatar>
                  </CCol>
                  <CCol
                    align="left"
                    sm="8"
                    style="margin-top:auto; margin-bottom:auto;"
                  >
                    <a
                      :href="link.web_link"
                    >
                      {{ link.name }}
                    </a>
                  </CCol>
                </CRow>
              </CCard>
            </a>
          </CCol>
        </CRow>
      </CTab>
      <CTab
        title="All Links"
      >
        <LinksTable
          class="mt-3"
          :current-links="links"
        />
      </CTab>
    </CTabs>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import LinksTable from '@/components/Links/LinksTable';
import _ from 'lodash';

library.add(fas);

export default {
  components: {
    LinksTable,
    FontAwesomeIcon,
  },
  computed: {
    teams() {
      return _.map(this.$store.state.teams.teams);
    },
    links() {
      return _.map(this.$store.state.links.knowledge);
    },
    linksByTeams() {
      const links = _.map(this.$store.state.links.knowledge);
      const sortByTeams = _.transform(links, (result, value, key) => {
        (result[value.team.id] || (result[value.team.id] = [])).push(value);
      }, {});

      return _.map(sortByTeams);
    },
  },
  created() {
    this.$store.dispatch('links/loadKnowledge');
    this.$store.dispatch('teams/loadTeams');
  },
};
</script>
