export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Dashboard',
        to: '/dashboard',
        icon: 'cil-speedometer',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'People',
        to: '/people',
        icon: 'cil-user',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Spaces',
        to: '/spaces',
        icon: 'cil-house',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Tacos',
        href: 'https://www.heytaco.chat/leaderboard',
        icon: 'cil-star',
        target: '_blank',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Timetastic',
        href: 'https://app.timetastic.co.uk/wallchart',
        icon: 'cil-clock',
        target: '_blank',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Knowledge Base',
        to: '/knowledge',
        icon: 'cil-cursor',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Teams',
        to: '/teams',
        icon: 'cil-blur-circular',
      },
    ],
  },
];
