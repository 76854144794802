import Vue from 'vue';
import VueRouter from 'vue-router';
import Container from '@/components/Container.vue';
import Home from '../views/Home.vue';
import Login from '../views/Login.vue';
import People from '../views/People.vue';
import Spaces from '../views/Spaces.vue';
import Links from '../views/Links.vue';
import KnowledgeBase from '../views/KnowledgeBase.vue';
import CurrentPeople from '../components/Persons/CurrentPeople.vue';
import CurrentSpace from '../views/CurrentSpace.vue';
import CreateTeam from '../components/Team/CreateTeam.vue';
import CreateLink from '../components/Links/CreateLink.vue';
import CreatePerson from '../components/Persons/CreatePerson.vue';
import MyProfile from '../components/Persons/MyProfile.vue';
import EditMyProfile from '../components/Persons/EditMyProfile.vue';
import CurrentTeam from '../components/Team/CurrentTeam.vue';
import CurrentLink from '../components/Links/CurrentLink.vue';
import Tacos from '../views/Tacos.vue';
import Teams from '../views/Teams.vue';
import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      rule: ['*'],
    },
  },
  {
    path: '/authentication/check_google',
    name: 'check_google',
    async beforeEnter(to, from, next) {
      try {
        await store.dispatch('authentication/login', to.query.code);
        next('/');
      } catch (e) {
        next('/login');
      }
    },
    meta: {
      rule: ['*'],
    },
  },
  {
    path: '/',
    redirect: '/dashboard',
    name: 'Home',
    component: Container,
    meta: {
      rule: 'isPublic',
    },
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Home,
        meta: {
          rule: 'isPublic',
        },
      }, {
        path: '/my-profile',
        name: 'My Profile',
        meta: {
          rule: 'isPublic',
        },
        component: {
          render(c: any) {
            return c('router-view');
          },
        },
        children: [{
          path: '/my-profile',
          name: 'My Profile',
          meta: {
            rule: 'isPublic',
          },
          component: MyProfile,
        }, {
          path: '/edit-my-profile',
          name: 'Edit Profile',
          meta: {
            rule: 'isPublic',
          },
          component: EditMyProfile,
        }],
      }, {
        path: '/people',
        name: 'People',
        redirect: '/people',
        meta: {
          rule: 'isPublic',
        },
        component: {
          render(c: any) {
            return c('router-view');
          },
        },
        children: [{
          path: '/people',
          name: 'All people',
          meta: {
            rule: 'isPublic',
          },
          component: People,
          async beforeEnter(to, from, next) {
            await store.dispatch('persons/loadPersons');
            next();
          },
        }, {
          path: '/people/:id',
          name: 'Person',
          props: true,
          component: CurrentPeople,
          meta: {
            label({ route }) {
              return route.instances.default?.currentPerson?.name;
            },
            rule: 'isPublic',
          },
          async beforeEnter(to, from, next) {
            await store.dispatch('persons/loadPersons');
            next();
          },
        }, {
          path: '/add-person',
          name: 'Add Person',
          component: CreatePerson,
          meta: {
            rule: 'isAdmin',
          },
          async beforeEnter(to, from, next) {
            await store.dispatch('teams/loadTeams');
            next();
          },
        }, {
          path: '/edit-person/:id',
          name: 'Edit Person',
          props: true,
          component: CreatePerson,
          meta: {
            rule: 'isAdmin',
          },
          async beforeEnter(to, from, next) {
            await store.dispatch('teams/loadTeams');
            next();
          },
        }],
      },
      {
        path: '/teams',
        name: 'Teams',
        redirect: '/teams',
        meta: {
          rule: 'isPublic',
        },
        component: {
          render(c: any) {
            return c('router-view');
          },
        },
        children: [{
          path: '/teams',
          name: 'All Teams',
          component: Teams,
          meta: {
            rule: 'isPublic',
          },
          async beforeEnter(to, from, next) {
            await store.dispatch('teams/loadTeams');
            next();
          },
        }, {
          path: '/teams/:id',
          name: 'Team',
          props: true,
          component: CurrentTeam,
          meta: {
            rule: 'isPublic',
            label({ route }) {
              return route.instances.default?.currentTeam?.name;
            },
          },
          async beforeEnter(to, from, next) {
            await store.dispatch('teams/loadTeams');
            next();
          },
        }, {
          path: '/teams-add',
          name: 'Add Team',
          props: true,
          meta: {
            rule: 'isAdmin',
          },
          component: CreateTeam,
          async beforeEnter(to, from, next) {
            await store.dispatch('teams/loadTeams');
            next();
          },
        }, {
          path: '/edit-team/:id',
          name: 'Edit Team',
          props: true,
          meta: {
            rule: 'isAdmin',
          },
          component: CreateTeam,
          async beforeEnter(to, from, next) {
            await store.dispatch('teams/loadTeams');
            next();
          },
        }],
      },
      {
        path: '/spaces',
        name: 'Spaces',
        redirect: '/spaces',
        meta: {
          rule: 'isPublic',
        },
        component: {
          render(c: any) {
            return c('router-view');
          },
        },
        children: [{
          path: '/spaces',
          name: 'All Spaces',
          meta: {
            rule: 'isPublic',
          },
          component: Spaces,
        }, {
          path: '/space/:id',
          name: 'Space',
          props: true,
          component: CurrentSpace,
          meta: {
            rule: 'isPublic',
            label({ route }) {
              return route.instances.default?.currentSpace?.name;
            },
          },
          async beforeEnter(to, from, next) {
            await store.dispatch('loadSpaces');
            next();
          },
        }],
      },
      {
        path: '/tacos',
        name: 'Tacos',
        redirect: '/tacos',
        meta: {
          rule: 'isPublic',
        },
        component: {
          render(c: any) {
            return c('router-view');
          },
        },
        children: [{
          path: '/tacos',
          name: 'All Tacos',
          meta: {
            rule: 'isPublic',
          },
          component: Tacos,
        },
        ],
      },
      {
        path: '/links',
        name: 'Links',
        redirect: '/links',
        meta: {
          rule: 'isAdmin',
        },
        component: {
          render(c: any) {
            return c('router-view');
          },
        },
        children: [{
          path: '/links',
          name: 'All Links',
          meta: {
            rule: 'isAdmin',
          },
          component: Links,
          async beforeEnter(fto, from, next) {
            await store.dispatch('links/loadLinks');
            next();
          },
        }, {
          path: '/link/:id',
          name: 'Link',
          props: true,
          component: CurrentLink,
          meta: {
            rule: 'isAdmin',
            label({ route }) {
              return route.instances.default?.currentLink?.name;
            },
          },
          async beforeEnter(to, from, next) {
            await store.dispatch('links/loadLinks');
            next();
          },
        }, {
          path: '/links-add',
          name: 'Add Link',
          props: true,
          component: CreateLink,
          meta: {
            rule: 'isAdmin',
          },
          async beforeEnter(to, from, next) {
            await store.dispatch('teams/loadTeams');
            next();
          },
        }, {
          path: '/links-edit/:id',
          name: 'Edit Link',
          props: true,
          component: CreateLink,
          meta: {
            rule: 'isAdmin',
          },
          async beforeEnter(to, from, next) {
            await store.dispatch('links/loadLinks');
            await store.dispatch('teams/loadTeams');
            next();
          },
        }],
      },
      {
        path: '/knowledge',
        name: 'Knowledge base',
        redirect: '/knowledge',
        meta: {
          rule: 'isPublic',
        },
        component: {
          render(c: any) {
            return c('router-view');
          },
        },
        children: [{
          path: '/knowledge',
          name: 'All Links',
          meta: {
            rule: 'isPublic',
          },
          component: KnowledgeBase,
          async beforeEnter(fto, from, next) {
            await store.dispatch('links/loadKnowledge');
            next();
          },
        }, {
          path: '/link/:id',
          name: 'Link',
          props: true,
          component: CurrentLink,
          meta: {
            rule: 'isPublic',
            label({ route }) {
              return route.instances.default?.currentLink?.name;
            },
          },
          async beforeEnter(to, from, next) {
            await store.dispatch('links/loadLinks');
            next();
          },
        }],
      },
    ],

  },
  // otherwise redirect to home
  {
    path: '*',
    redirect: '/',
    meta: {
      rule: ['*'],
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

const DEFAULT_TITLE = 'Spaceflow-Portal';
router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || `${DEFAULT_TITLE} | ${to.name}`;
  });
});

export default router;
