import _ from 'lodash';
import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import JwtService from '@/common/jwt.service';
import { API_URL as apiUrl } from '@/common/config';

const api = axios.create({
  baseURL: apiUrl,
  transformRequest: []
    .concat(axios.defaults.transformRequest)
    .concat((data, headers) => {
      const token = JwtService.getToken();
      if (token) {
        headers.common.Authorization = `Bearer ${JwtService.getToken()}`;
      }
      return data;
    }),
});

api.interceptors.response.use(
  (response) => response, (error) => {
    if (_.get(error, 'request.status') === 401) {
      // store.commit('authentication/logout');
    }
    return Promise.reject(error);
  },
);

Vue.use(VueAxios, axios);

const ApiService = api;

export default ApiService;
