<template>
  <CHeader
    fixed
    with-subheader
    light
  >
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="toggleSidebarMobile()"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="toggleSidebarDesktop()"
    />
    <CHeaderBrand
      class="mx-auto d-lg-none"
      to="/"
    >
      <CIcon
        name="logo"
        height="48"
        alt="Logo"
        class="p-2 mt-2 w-75"
      />
    </CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto">
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/dashboard">
          Dashboard
        </CHeaderNavLink>
      </CHeaderNavItem>
    </CHeaderNav>
    <CHeaderNav class="mr-4">
      <HeaderAccountDropdown />
    </CHeaderNav>
    <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0 mb-0" />
    </CSubheader>
  </CHeader>
</template>

<script>
import { mapActions } from 'vuex';
import CBreadcrumbRouter from '@/components/CBreadcrumbRouter.vue';
import HeaderAccountDropdown from './HeaderAccountDropdown';

export default {

  name: 'Header',
  components: {
    HeaderAccountDropdown,
    CBreadcrumbRouter,
  },
  methods: {
    ...mapActions([
      'toggleSidebarMobile',
      'toggleSidebarDesktop',
    ]),
  },
};
</script>
