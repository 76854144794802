<template>
  <div class="avatar-cropper">
    <div
      class="avatar-dropzone"
      @dragover="dragover"
      @drop="drop"
      @click="pickImage"
    >
      <CSpinner
        v-if="uploading"
        color="primary"
        class="uploading"
      />
      <div
        v-if="!uploading && !value"
        class="text-center"
      >
        Drop image here or click to select. <br /> (max file size is 2MB)
      </div>
      <img
        v-if="!uploading && value"
        :src="value"
      >
    </div>
    <button
      class="pick-avatar"
      @click="pickImage"
    >
      Select an image
    </button>
    <div
      v-if="dataUrl"
      class="avatar-cropper-overlay"
    >
      <div class="avatar-cropper-mark">
        <a
          href="javascript:;"
          class="avatar-cropper-close"
          @click="destroy"
        >&times;</a>
      </div>
      <div class="avatar-cropper-container">
        <div class="avatar-cropper-image-container">
          <img
            ref="img"
            :src="dataUrl"
            @load.stop="createCropper"
          >
        </div>
        <div class="avatar-cropper-footer">
          <button
            class="avatar-cropper-btn"
            @click.stop.prevent="destroy"
            v-text="labels.cancel"
          >
            Cancel
          </button>
          <button
            class="avatar-cropper-btn"
            @click.stop.prevent="submit"
            v-text="labels.submit"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
    <input
      ref="input"
      :accept="mimes"
      class="avatar-cropper-img-input"
      type="file"
      @change="fileChange($event.target.files)"
    >
  </div>
</template>

<script>
import 'cropperjs/dist/cropper.css';
import Cropper from 'cropperjs';
import UploadService from '@/common/upload.service';

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    trigger: {
      type: [String, Element],
      required: true,
    },
    uploadFormName: {
      type: String,
      default: 'file',
    },
    uploadFormData: {
      type: Object,
      default() {
        return {};
      },
    },
    cropperOptions: {
      type: Object,
      default() {
        return {
          aspectRatio: 1,
          autoCropArea: 1,
          viewMode: 1,
          movable: false,
          zoomable: false,
        };
      },
    },
    outputMime: {
      type: String,
      default: null,
    },
    outputQuality: {
      type: Number,
      default: 0.9,
    },
    mimes: {
      type: String,
      default: 'image/png, image/gif, image/jpeg, image/bmp, image/x-icon',
    },
    labels: {
      type: Object,
      default() {
        return {
          submit: 'Submit',
          cancel: 'Cancel',
        };
      },
    },
    withCredentials: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      uploading: false,
      cropper: undefined,
      dataUrl: undefined,
      filename: undefined,
    };
  },
  mounted() {
    // listen for click event on trigger
    const trigger = typeof this.trigger === 'object' ? this.trigger : document.querySelector(this.trigger);
    if (!trigger) {
      this.$emit('error', 'No avatar make trigger found.', 'user');
    } else {
      trigger.addEventListener('click', this.pickImage);
    }
  },
  methods: {
    dragover(event) {
      event.preventDefault();
      this.fileChange(event.dataTransfer.files);
    },
    drop(event) {
      event.preventDefault();
      this.fileChange(event.dataTransfer.files);
    },
    destroy() {
      this.cropper.destroy();
      this.$refs.input.value = '';
      this.dataUrl = undefined;
    },
    submit() {
      this.$emit('submit');
      this.uploadImage();
      this.destroy();
    },
    pickImage(e) {
      this.$refs.input.click();
      e.preventDefault();
      e.stopPropagation();
    },
    createCropper() {
      this.cropper = new Cropper(this.$refs.img, this.cropperOptions);
    },
    async uploadImage() {
      this.uploading = true;
      this.cropper.getCroppedCanvas(this.outputOptions).toBlob(async (blob) => {
        const formData = new FormData();
        formData.append('file_upload[file]', blob, this.filename);
        let res;
        try {
          res = await UploadService.uploadFile(formData);
          this.$emit('input', res.data);
        } catch (error) {
          this.$store.dispatch('alert/error', 'File is bigger then 2 MB.');
        } finally {
          this.uploading = false;
        }
        return res;
      }, 'image/jpeg', 0.9);
    },
    fileChange(files) {
      if (files != null && files[0] != null) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.dataUrl = e.target.result;
        };

        reader.readAsDataURL(files[0]);

        this.filename = files[0].name || 'unknown';
        this.mimeType = this.mimeType || files[0].type;
        this.$emit('changed', files[0], reader);
        this.uploading = false;
      }
    },
  },
};
</script>
