<template>
  <div>
    <ValidationObserver ref="validator">
      <form
        @submit.prevent="checkForm"
      >
        <CCard>
          <CCardBody>
            <CRow>
              <CCol sm="12">
                <ValidationProvider
                  v-slot="{errors, valid, validated}"
                  :rules="person.$validations.name"
                  name="Name"
                >
                  <CInput
                    v-model="person.name"
                    name="name"
                    label="Name"
                    placeholder="John Doe"
                    :is-valid="!validated ? null : valid"
                    :invalid-feedback="errors[0]"
                  />
                </ValidationProvider>
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="12">
                <ValidationProvider
                  v-slot="{errors, valid, validated}"
                  :rules="person.$validations.position"
                  name="Position"
                >
                  <CInput
                    v-model="person.position"
                    label="Position"
                    name="position"
                    placeholder="Software developer"
                    :is-valid="!validated ? null : valid"
                    :invalid-feedback="errors[0]"
                  />
                </ValidationProvider>
              </CCol>
            </CRow>
            <CRow>
              <CCol
                sm="12"
                class="pb-3"
              >
                <ValidationProvider
                  v-slot="{errors, passed, failed}"
                  :rules="person.$validations.avatar"
                  name="Avatar"
                >
                  <label
                    sm="12"
                  >
                    Upload avatar
                  </label>
                  <avatar-cropper
                    v-model="person.avatar"
                    trigger="#pick-avatar"
                    :class="{
                      'is-valid': passed,
                      'is-invalid': failed
                    }"
                  />
                  <span class="invalid invalid-feedback">{{ errors[0] }}</span>
                </ValidationProvider>
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="12">
                <div
                  role="group"
                  class="form-group"
                >
                  <label
                    for="birthDate"
                  >
                    Birth Date
                  </label>
                  <ValidationProvider
                    v-slot="{errors, passed, failed}"
                    :rules="person.$validations.birth_date"
                    name="Birth date"
                  >
                    <datepicker
                      id="birthDate"
                      v-model="birthDate"
                      name="birthDate"
                      format="yyyy-MM-dd"
                      :input-class="[
                        'form-control',
                        {
                          'is-valid': passed,
                          'is-invalid': failed
                        }
                      ]"
                      :class="{
                        'is-valid': passed,
                        'is-invalid': failed
                      }"
                      placeholder="Birth date of person..."
                    />
                    <span class="invalid invalid-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="12">
                <ValidationProvider
                  v-slot="{errors, valid, validated}"
                  :rules="person.$validations.phone_number"
                  name="Phone number"
                >
                  <CInput
                    v-model="person.phone_number"
                    type="tel"
                    label="Phone number"
                    placeholder="+420111222333"
                    :is-valid="!validated ? null : valid"
                    :invalid-feedback="errors[0]"
                  />
                </ValidationProvider>
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="12">
                <ValidationProvider
                  v-slot="{errors, valid, validated}"
                  :rules="person.$validations.email"
                  name="email"
                >
                  <CInput
                    v-model="person.email"
                    label="Email"
                    type="email"
                    placeholder="john.doe@spaceflow.com"
                    :is-valid="!validated ? null : valid"
                    :invalid-feedback="errors[0]"
                  />
                </ValidationProvider>
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="12">
                <ValidationProvider
                  v-slot="{errors, valid, validated}"
                  :rules="person.$validations.long_description"
                  name="Description"
                >
                  <TipTap
                    v-model="person.long_description"
                    label="Description"
                    placeholder="I am john doe..."
                    :is-valid="!validated ? null : valid"
                    :invalid-feedback="errors[0]"
                  />
                </ValidationProvider>
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="12">
                <ValidationProvider
                  v-slot="{errors, passed, failed}"
                  :rules="person.$validations.team_id"
                  name="Team"
                >
                  <label class="typo__label">
                    Teams
                  </label>
                  <multiselect
                    v-model="person.teams"
                    tag-placeholder="Add to the team"
                    placeholder="Add to team"
                    label="title"
                    track-by="id"
                    :options="teamOptions"
                    :multiple="true"
                    :taggable="true"
                    :invalid-feedback="errors[0]"
                    :class="{
                      'is-valid': passed,
                      'is-invalid': failed
                    }"
                  />
                  <span class="invalid invalid-feedback">{{ errors[0] }}</span>
                </ValidationProvider>
              </CCol>
            </CRow>
            <CRow>
              <CCol
                sm="12"
                class="mt-2"
              >
                <ValidationProvider
                  v-slot="{errors, valid, validated}"
                  :rules="person.$validations.roles"
                  name="Role"
                >
                  <CSelect
                    :value="roles"
                    label="Role"
                    :options="rolesOptions"
                    :invalid-feedback="errors[0]"
                    :is-valid="!validated ? null : valid"
                    @change="onChangeRole"
                  />
                </ValidationProvider>
              </CCol>
            </CRow>
            <CRow>
              <CCol
                sm="12"
                align="right"
              >
                <CButton
                  color="primary"
                  type="submit"
                  class="ml-auto mb-2 mt-3"
                  :disabled="sending"
                >
                  <CSpinner
                    v-if="sending"
                    color="info"
                    size="sm"
                  />
                  Save
                </CButton>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import Person from '@/model/person/person';
import store from '@/store';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import Datepicker from 'vuejs-datepicker';
import Multiselect from 'vue-multiselect';
import moment from 'moment';
import { UserRoles } from '@/helpers';
import AvatarCropper from '@/components/AvatarCropper';
import _ from 'lodash';
import TipTap from '@/components/Input/TipTap';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Datepicker,
    Multiselect,
    AvatarCropper,
    TipTap,
  },
  data() {
    return {
      person: new Person(),
      sending: false,
    };
  },
  async beforeRouteEnter(to, from, next) {
    if (to.params.id) {
      const person = await store.dispatch('persons/loadPerson', to.params.id);
      next((vm) => {
        vm.person = person;
      });
    } else {
      next((vm) => {
        vm.person.roles = [UserRoles.user];
      });
    }
  },
  computed: {
    roles() {
      if (_.isNil(this.person.id)) {
        return [UserRoles.user];
      }
      return this.person.roles[0];
    },
    rolesOptions() {
      return [
        { label: 'User', value: UserRoles.user },
        { label: 'Admin', value: UserRoles.admin },
      ];
    },
    teamId: {
      get() {
        const teamOptionsByCode = _.mapKeys(this.teamOptions, 'code');
        return _.map(
          this.person.team_id,
          (code) => teamOptionsByCode[code] || {
            code,
            name: code,
          },
        );
      },
      set(val) {
        this.person.team_id = _.isNil(val) ? [] : _.map(val, 'code');
      },
    },
    teamOptions() {
      return _.map(this.$store.state.teams.teams, (team) => ({
        id: team.id,
        title: team.name,
      }));
    },
    birthDate: {
      get() {
        if (_.isNil(this.person.birth_date)) {
          return null;
        }
        return moment(this.person.birth_date)
          .format('Y-MM-DD');
      },
      set(value) {
        this.person.birth_date = moment(value).format();
      },
    },
  },
  methods: {
    onChangeTeam(event) {
      this.person.teamId = event.target.value;
    },
    onChangeRole(event) {
      this.person.roles = [event.target.value];
    },
    async checkForm() {
      try {
        this.sending = true;
        const valid = await this.$refs.validator.validate();
        if (valid) {
          await this.$store.dispatch('persons/savePerson', this.person);
          this.$router.push('/people');
          this.$store.dispatch('alert/success', 'success');
        }
      } catch (error) {
        this.$store.dispatch('alert/error', 'error');
      } finally {
        this.sending = false;
      }
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
