<script>
import _ from 'lodash';
import CBreadcrumbRouter from '@coreui/vue/src/components/breadcrumb/CBreadcrumbRouter.vue';

export default {
  extends: CBreadcrumbRouter,
  data() {
    return {
      matchedRoutes: [],
    };
  },
  computed: {
    items() {
      const routes = this.matchedRoutes.filter(
        (route) => route.name || (route.meta && route.meta.label),
      );
      return routes.map((route) => {
        const meta = route.meta || {};
        return {
          to: route,
          text: _.isFunction(meta.label) ? meta.label({ routes, route }) : meta.label || route.name,
        };
      });
    },
  },
  created() {
    this.$router.afterEach(async () => {
      await this.$nextTick();
      this.matchedRoutes = this.$route.matched;
    });
  },
  async mounted() {
    await this.$nextTick();
    this.matchedRoutes = this.$route.matched;
  },
};
</script>
