import _ from 'lodash';
import { UserRoles } from '@/helpers';
import Model from '../Model';

export default class Person extends Model({
  id: {
    type: 'string',
  },
  description: {
    type: 'string',
  },
  name: {
    type: 'string',
    validations: {
      required: true,
    },
  },
  position: {
    type: 'string',
    validations: {
      required: true,
    },
  },
  birth_date: {
    type: 'string',
    validations: {
      required: true,
    },
  },
  phone_number: {
    type: 'string',
    validations: {
      required: true,
    },
  },
  email: {
    type: 'string',
    validations: {
      required: true,
    },
  },
  avatar: {
    type: 'string',
    validations: {
      required: true,
    },
  },
  long_description: {
    type: 'string',
    validations: {
      required: true,
      min: 100,
    },
  },
  team_id: {
    type: 'array',
    validations: {
      required: true,
    },
  },
  teams: {
    type: 'array',
    validations: {
      required: true,
    },
  },
  roles: {
    type: 'array',
    validations: {
      required: true,
    },
  },
}) {
  constructor(data) {
    super(data);
    if (!this._data.team_id && this._data.teams) {
      this._data.team_id = _.map(this._data.teams, 'id');
    }
  }

  get teams() {
    return this._data.teams;
  }

  set teams(val) {
    this._data.team_id = _.map(val, 'id');
    this._data.teams = val;
  }

  get roles() {
    if (_.includes(this._data.roles, UserRoles.admin)) {
      return [UserRoles.admin];
    }
    return [UserRoles.user];
  }

  set roles(val) {
    this._data.roles = val;
  }
}
