import Vue from 'vue';
import './tiptap.css';
import TipTapLoader from './TipTapLoader.vue';

/**
 * Lazy load TipTap component since it's huge,
 * show non editable preview while it's loading
 */
export default Vue.extend({
  inheritAttrs: false,
  data() {
    return {
      component: null,
    };
  },
  async mounted() {
    this.component = (await import(/* webpackChunkName: 'TipTap' */ './TipTap.vue')).default;
  },
  render(h) {
    return h(this.component ? this.component : TipTapLoader, {
      props: this.$attrs,
      on: this.$listeners,
    });
  },
});
