<template>
  <CSidebar
    fixed
    :minimize="minimize"
    :show="show"
    @update:show="(value) => toggleSidebarMinimize()"
  >
    <CSidebarBrand
      class="d-md-down-none"
      to="/"
      :content="'@/assets/logo.png'"
    >
      <img
        class="c-sidebar-brand-full w-100 p-2"
        :height="35"
        viewBox="0 0 110 134"
        src="@/assets/spaceflow_logo.svg"
        block
      >
      <img
        class="c-sidebar-brand-minimized w-100 p-2"
        :height="35"
        viewBox="0 0 110 134"
        src="@/assets/sf_white.svg"
        block
      >
    </CSidebarBrand>
    <CRenderFunction
      flat
      :content-to-render="$options.nav"
    />
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="toggleSidebarMinimize()"
    />
  </CSidebar>
</template>

<script>
import { mapActions } from 'vuex';
import nav from './_nav.ts';

export default {
  name: 'Sidebar',
  nav,
  computed: {
    show() {
      return this.$store.state.sidebarShow;
    },
    minimize() {
      return this.$store.state.sidebarMinimize;
    },
  },
  methods: {
    ...mapActions([
      'toggleSidebarMinimize',
    ]),
  },
};
</script>
