import API from '@/common/api.service';

export const tacos = {
  namespaced: true,
  state: {
    tacos: [],
  },
  actions: {
    async loadTacos({ commit }) {
      const tacos = await API.get('/v1/heytaco/lb');
      commit('SAVE_TACOS', tacos.data);
      return tacos.data;
    },
  },
  mutations: {
    SAVE_TACOS(state: any, tacos) {
      state.tacos = tacos;
    },
  },
};
