<template>
  <CCard>
    <CCardBody>
      <CDataTable
        table-filter
        items-per-page-select
        :items-per-page="20"
        :fields="fields"
        hover
        striped
        :loading="false"
        :sorter="true"
        responsive
        pagination
        :items="currentTeams"
        align="center"
      >
        <template #name="{item}">
          <td>
            <router-link :to="'/teams/' + item.id">
              {{ item.name }}
            </router-link>
          </td>
        </template>
        <template #description="{item}">
          <td>
            {{ item.description | truncate(200) }}
          </td>
        </template>
        <template #delete="{item}">
          <td align="right">
            <CButton
              v-if="$acl.check('isAdmin')"
              class="btn btn-outline-primary mr-2"
              :to="`/edit-team/${item.id}`"
            >
              Update
            </CButton>
            <CButton
              v-if="$acl.check('isAdmin')"
              class="btn btn-danger mr-2"
              @click="deleteTeam(item.id)"
            >
              DELETE
            </CButton>
          </td>
        </template>
      </CDataTable>
    </CCardBody>
  </CCard>
</template>

<script>

export default {
  props: {
    currentTeams: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: 'Name',
          sorter: true,
        },
        {
          key: 'description',
          label: 'Description',
          sorter: true,
        },
        {
          key: 'delete',
          label: '',
          sorter: false,
        },
      ],
    };
  },
  methods: {
    async deleteTeam(id) {
      await this.$store.dispatch('teams/deleteTeam', id);
      this.$store.dispatch('teams/loadTeams');
    },
  },
};
</script>
