<template>
  <CContainer class="d-flex content-center min-vh-100">
    <CCard class="p-1 col-8">
      <CCardBody>
        <CIcon
          name="logo"
          height="60"
          alt="Logo"
          class="mt-3 w-100"
        />
        <CForm>
          <h1>Portal</h1>
          <p class="text-muted">
            Sign In to your account
          </p>
          <CRow>
            <CLink
              href="/api/v1/authentication/start_google"
              class="ml-auto mr-auto"
            >
              <div class="google-btn">
                <div class="google-icon-wrapper">
                  <img
                    class="google-icon"
                    src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
                  >
                </div>
                <p class="btn-text">
                  <b>Sign in with google</b>
                </p>
              </div>
            </CLink>
          </CRow>
        </CForm>
      </CCardBody>
    </CCard>
  </CContainer>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Login',
  data() {
    return {
      username: '',
      password: '',
      submitted: false,
    };
  },
  computed: {
    ...mapState(['user']),
    loggingIn() {
      return this.$store.state.authentication.status.loggingIn;
    },
  },
  created() {
    this.$store.dispatch('authentication/logout');
  },
  methods: {
    handleSubmit() {
      window.location.href = '';
    },
  },
};
</script>
