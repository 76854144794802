import { userService } from '../common/user.services';
import { saveToken, getToken, destroyToken } from '../common/jwt.service';

const initialState = {
  token: getToken(),
};

export const authentication = {
  namespaced: true,
  state: initialState,
  getters: {
    jwtUser(state) {
      if (state.token) {
        return JSON.parse(atob(state.token.split('.')[1]));
      }
      return null;
    },
    tokenValidTill(state, getter) {
      if (!getter.jwtUser) {
        return null;
      }
      return new Date(getter.jwtUser.exp * 1000);
    },
  },
  actions: {
    async login({ commit, dispatch }, code) {
      try {
        const token = await userService.login(code);
        commit('saveToken', token);
        commit('loginSuccess');
      } catch (error) {
        commit('loginFailure', error);
        dispatch('alert/error', error, { root: true });
        throw error;
      }
    },
    logout({ commit }) {
      userService.logout();
      commit('logout');
    },
  },
  mutations: {
    saveToken(state, token) {
      state.token = token;
      saveToken(token);
    },
    loginRequest(state) {
      state.status = { loggingIn: true };
    },
    loginSuccess(state) {
      state.status = { loggedIn: true };
    },
    loginFailure(state) {
      state.status = {};
      state.user = null;
    },
    logout(state) {
      state.status = {};
      state.user = null;
      destroyToken();
    },
  },
};
