var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"validator"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.checkForm($event)}}},[_c('CCard',[_c('CCardBody',[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('ValidationProvider',{attrs:{"rules":_vm.person.$validations.name,"name":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var validated = ref.validated;
return [_c('CInput',{attrs:{"name":"name","label":"Name","placeholder":"John Doe","is-valid":!validated ? null : valid,"invalid-feedback":errors[0]},model:{value:(_vm.person.name),callback:function ($$v) {_vm.$set(_vm.person, "name", $$v)},expression:"person.name"}})]}}])})],1)],1),_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('ValidationProvider',{attrs:{"rules":_vm.person.$validations.position,"name":"Position"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var validated = ref.validated;
return [_c('CInput',{attrs:{"label":"Position","name":"position","placeholder":"Software developer","is-valid":!validated ? null : valid,"invalid-feedback":errors[0]},model:{value:(_vm.person.position),callback:function ($$v) {_vm.$set(_vm.person, "position", $$v)},expression:"person.position"}})]}}])})],1)],1),_c('CRow',[_c('CCol',{staticClass:"pb-3",attrs:{"sm":"12"}},[_c('ValidationProvider',{attrs:{"rules":_vm.person.$validations.avatar,"name":"Avatar"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var passed = ref.passed;
var failed = ref.failed;
return [_c('label',{attrs:{"sm":"12"}},[_vm._v(" Upload avatar ")]),_c('avatar-cropper',{class:{
                    'is-valid': passed,
                    'is-invalid': failed
                  },attrs:{"trigger":"#pick-avatar"},model:{value:(_vm.person.avatar),callback:function ($$v) {_vm.$set(_vm.person, "avatar", $$v)},expression:"person.avatar"}}),_c('span',{staticClass:"invalid invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('div',{staticClass:"form-group",attrs:{"role":"group"}},[_c('label',{attrs:{"for":"birthDate"}},[_vm._v(" Birth Date ")]),_c('ValidationProvider',{attrs:{"rules":_vm.person.$validations.birth_date,"name":"Birth date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
                  var passed = ref.passed;
                  var failed = ref.failed;
return [_c('datepicker',{class:{
                      'is-valid': passed,
                      'is-invalid': failed
                    },attrs:{"id":"birthDate","name":"birthDate","format":"yyyy-MM-dd","input-class":[
                      'form-control',
                      {
                        'is-valid': passed,
                        'is-invalid': failed
                      }
                    ],"placeholder":"Birth date of person..."},model:{value:(_vm.birthDate),callback:function ($$v) {_vm.birthDate=$$v},expression:"birthDate"}}),_c('span',{staticClass:"invalid invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1),_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('ValidationProvider',{attrs:{"rules":_vm.person.$validations.phone_number,"name":"Phone number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
                    var valid = ref.valid;
                    var validated = ref.validated;
return [_c('CInput',{attrs:{"type":"tel","label":"Phone number","placeholder":"+420111222333","is-valid":!validated ? null : valid,"invalid-feedback":errors[0]},model:{value:(_vm.person.phone_number),callback:function ($$v) {_vm.$set(_vm.person, "phone_number", $$v)},expression:"person.phone_number"}})]}}])})],1)],1),_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('ValidationProvider',{attrs:{"rules":_vm.person.$validations.email,"name":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
                    var valid = ref.valid;
                    var validated = ref.validated;
return [_c('CInput',{attrs:{"label":"Email","type":"email","placeholder":"john.doe@spaceflow.com","is-valid":!validated ? null : valid,"invalid-feedback":errors[0]},model:{value:(_vm.person.email),callback:function ($$v) {_vm.$set(_vm.person, "email", $$v)},expression:"person.email"}})]}}])})],1)],1),_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('ValidationProvider',{attrs:{"rules":_vm.person.$validations.long_description,"name":"Description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
                    var valid = ref.valid;
                    var validated = ref.validated;
return [_c('TipTap',{attrs:{"label":"Description","placeholder":"I am john doe...","is-valid":!validated ? null : valid,"invalid-feedback":errors[0]},model:{value:(_vm.person.long_description),callback:function ($$v) {_vm.$set(_vm.person, "long_description", $$v)},expression:"person.long_description"}})]}}])})],1)],1),_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('ValidationProvider',{attrs:{"rules":_vm.person.$validations.team_id,"name":"Team"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
                    var passed = ref.passed;
                    var failed = ref.failed;
return [_c('label',{staticClass:"typo__label"},[_vm._v(" Teams ")]),_c('multiselect',{class:{
                    'is-valid': passed,
                    'is-invalid': failed
                  },attrs:{"tag-placeholder":"Add to the team","placeholder":"Add to team","label":"title","track-by":"id","options":_vm.teamOptions,"multiple":true,"taggable":true,"invalid-feedback":errors[0]},model:{value:(_vm.person.teams),callback:function ($$v) {_vm.$set(_vm.person, "teams", $$v)},expression:"person.teams"}}),_c('span',{staticClass:"invalid invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('CRow',[_c('CCol',{staticClass:"mt-2",attrs:{"sm":"12"}},[_c('ValidationProvider',{attrs:{"rules":_vm.person.$validations.roles,"name":"Role"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
                  var valid = ref.valid;
                  var validated = ref.validated;
return [_c('CSelect',{attrs:{"value":_vm.roles,"label":"Role","options":_vm.rolesOptions,"invalid-feedback":errors[0],"is-valid":!validated ? null : valid},on:{"change":_vm.onChangeRole}})]}}])})],1)],1),_c('CRow',[_c('CCol',{attrs:{"sm":"12","align":"right"}},[_c('CButton',{staticClass:"ml-auto mb-2 mt-3",attrs:{"color":"primary","type":"submit","disabled":_vm.sending}},[(_vm.sending)?_c('CSpinner',{attrs:{"color":"info","size":"sm"}}):_vm._e(),_vm._v(" Save ")],1)],1)],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }