<template>
  <div
    v-if="currentPerson"
  >
    <CRow align="center">
      <CCol
        lg="4"
        md="6"
        sm="12"
      >
        <CCard>
          <CCardBody>
            <CRow>
              <CCol sm="12">
                <b-avatar
                  v-if="currentPerson.avatar"
                  variant=""
                  :src="`${currentPerson.avatar}`"
                  size="15rem"
                />
                <CCol
                  sm="12"
                  align="center"
                >
                  <h1 class="name">
                    {{ currentPerson.name }}
                  </h1>
                </CCol>
                <CCol
                  sm="12"
                  align="center"
                >
                  {{ currentPerson.position }}
                </CCol>
                <CCol
                  sm="12"
                  align="center"
                >
                  {{ currentPerson.phone_number }}
                </CCol>
                <CCol
                  sm="12"
                  align="center"
                >
                  <a
                    :href="`mailto:${ currentPerson.email }`"
                  >
                    {{ currentPerson.email }}
                  </a>
                </CCol>
                <CCol
                  sm="12"
                  align="center"
                >
                  🎂 {{ currentPerson.birth_date | birthDate }}
                </CCol>
              </CCol>
              <CButton
                :to="'/edit-my-profile/'"
                class="btn btn-outline-primary mr-auto ml-auto mt-3"
              >
                Edit my profile
              </CButton>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol
        lg="8"
        md="6"
        sm="12"
      >
        <CCard>
          <CCardBody>
            <CRow>
              <CCol
                sm="12"
                align="left"
              >
                <h2>
                  Description
                </h2>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <div v-html="sanitizedDescription" />
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
        <CCard>
          <CCardBody>
            <CRow>
              <CCol
                sm="12"
                align="left"
                class="mt-2"
              >
                <h2>
                  Teams
                </h2>
                <CBadge
                  v-for="team in currentPerson.teams"
                  :key="team.title"
                  color="primary"
                  class="mr-1"
                >
                  <router-link
                    :to="`/teams/${team.id}`"
                    class="text-white"
                  >
                    {{ team.title }}
                  </router-link>
                </CBadge>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import moment from 'moment';
import sanitizeHtml from 'sanitize-html';

export default {
  filters: {
    birthDate(value) {
      return moment(value)
        .format('MMMM DD');
    },
  },
  computed: {
    sanitizedDescription() {
      return sanitizeHtml(this.currentPerson.description);
    },
    currentPerson() {
      return this.$store.state.persons.persons[this.$store.state.persons.me.id];
    },
  },
  created() {
    this.$store.dispatch('persons/loadPersons');
  },
};
</script>
