<template>
  <div id="App">
    <router-view />
  </div>
</template>
<script>
export default {
  name: 'App',
  watch: {
    $route() {
      this.$store.dispatch('alert/clear');
    },
  },
};
</script>
