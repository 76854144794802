import API from '@/common/api.service';
import Person from '@/model/person/person';
import Me from '@/model/person/me';
import _ from 'lodash';

export const persons = {
  namespaced: true,
  state: {
    persons: [],
    person: Person,
    me: {},
  },
  actions: {
    async loadMe({ commit }) {
      const me = await API.get('/v1/me');
      commit('SAVE_ME', me.data);
      return me.data;
    },
    async editMyProfile({ commit }, data: Me) {
      const me = await API.put('/v1/person/me', data.$data);
      return me.data;
    },
    async loadPersons({ commit }) {
      const persons = await API.get('/v1/person/retrieve/all');
      commit('SAVE_PERSONS', persons.data);
      return persons.data;
    },
    async loadPerson({ commit }, id: string) {
      const persons = new Person((await API.get(`/v1/person/${id}`)).data);
      return persons;
    },
    async savePerson({ commit }, data: Person) {
      if (data.id) {
        return new Person((await API.put(`/v1/person/${data.id}`, data.$data)).data);
      }
      return new Person((await API.post('/v1/person/create', data.$data)).data);
    },
    async deletePerson({ commit }, id: string) {
      const persons = await API.delete(`/v1/person/${id}`);
      return persons.data;
    },
  },
  mutations: {
    SAVE_PERSON(state: any, person) {
      state.person = person;
    },
    SAVE_PERSONS(state: any, persons) {
      state.persons = _.chain(persons)
        .map((person) => new Person(person))
        .mapKeys('id')
        .value();
    },
    SAVE_ME(state: any, persons) {
      state.me = persons;
    },
  },
};
