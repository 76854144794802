<template>
  <div>
    <h1>Hi {{ user.name }}!</h1>

    <iframe src="https://theorg.com/org/spaceflow/embed" width="100%" height="550px" frameBorder="0"></iframe>

    <google-map
      v-if="places.length"
      :places="places"
      :zoom="2"
    />
  </div>
</template>

<script>
import GoogleMap from '@/components/GoogleMap';
import _ from 'lodash';

export default {
  name: 'App',
  components: {
    GoogleMap,
  },
  data() {
    return {
      user: this.$store.state.persons.me,
    };
  },
  computed: {
    places() {
      return _.map(this.$store.state.spaces) || [];
    },
  },
  created() {
    this.getPlaces();
  },
  methods: {
    async getPlaces() {
      await this.$store.dispatch('loadSpaces');
    },
  },
};
</script>
