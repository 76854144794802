<template>
  <div>
    <CRow align="left">
      <CCol
        sm="12"
      >
        <h1>
          {{ currentTeam.name }}
        </h1>
      </CCol>
      <CCol
        sm="12"
      >
        <CCard>
          <CCardBody>
            <CRow>
              <CCol
                sm="12"
                align="left"
              >
                {{ currentTeam.description }}
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
        <PeopleTable
          v-if="persons"
          :current-people="persons"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import PeopleTable from '@/components/Persons/PeopleTable';
import _ from 'lodash';

export default {
  components: {
    PeopleTable,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    currentTeam() {
      return this.$store.state.teams.teams[this.id];
    },
    persons() {
      const personIdsInCurrentTeam = new Set(_.chain(this.currentTeam.persons)
        .map('id')
        .reject(_.isNil)
        .value());
      return _.map(_.filter(this.$store.state.persons.persons,
        ({ id }) => personIdsInCurrentTeam.has(id)), (person) => person._data);
    },
  },
  created() {
    this.$store.dispatch('persons/loadPersons');
  },
};
</script>
