import Model from '../Model';

export default class Link extends Model({
  id: {
    type: 'string',
    validations: {
      required: false,
    },
  },
  name: {
    type: 'string',
    validations: {
      required: true,
    },
  },
  image_url: {
    type: 'string',
    validations: {
      required: true,
    },
  },
  web_link: {
    type: 'string',
    validations: {
      required: true,
    },
  },
  team_uuid: {
    type: 'string',
    validations: {
      required: true,
    },
  },
}) {
}
