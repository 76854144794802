<template>
  <div
    class="space-qrcode"
    :class="[`space-qrcode--${type}`]"
  >
    <CButton
      v-if="type === 'button'"
      class="btn btn-outline-primary mr-2"
      @click="downloadQrCode"
    >
      <CIcon :content="$options.icons.cilQrCode" />
    </CButton>
    <CCard
      v-else-if="generatedQrCode"
      class="m-0"
    >
      <CCardBody>
        <div class="space-qrcode__wrapper">
          <!-- eslint-disable vue/no-v-html -->
          <div
            class="space-qrcode__svg"
            @click="downloadQrCode"
            v-html="generatedQrCode"
          />
          <!-- eslint-enable vue/no-v-html -->
        </div>
      </CCardBody>
    </CCard>
    <CCard
      v-else
      class="m-0"
    >
      <CCardBody>
        <div class="space-qrcode__wrapper">
          <div class="space-qrcode__loader">
            <CSpinner />
          </div>
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import { cilQrCode } from '@coreui/icons';
import { downloadBlob } from '@/helpers/download';

export default {
  icons: {
    cilQrCode,
  },
  props: {
    type: {
      type: String,
      default: 'qrcode',
      enum: [
        'button',
        'qrcode',
      ],
    },
    space: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      generatedQrCode: null,
    };
  },
  computed: {
    url() {
      return `https://spaceflow.io/download?buildingId=${this.space.id}&accessCode=${this.space.accessCode}`;
    },
  },
  watch: {
    space: {
      immediate: true,
      async handler() {
        if (this.type === 'qrcode') {
          this.generatedQrCode = null;
          this.generatedQrCode = await this.generateSVG();
        }
      },
    },
  },
  methods: {
    async generateSVG() {
      return (await this.generateQrCode()).svg();
    },
    async downloadQrCode() {
      const blob = new Blob([(await this.generateSVG())], { type: 'image/svg' });
      downloadBlob(blob, `${this.space.name}.svg`);
    },
    async generateQrCode() {
      const QRCode = (await import(/* webpackChunkName: 'qrcode' */'qrcode-svg')).default;
      return new QRCode({
        content: this.url,
        padding: 4,
        width: 256,
        height: 256,
        container: 'svg-viewbox',
        color: '#000000',
        background: '#ffffff',
        ecl: 'M',
      });
    },
  },
};
</script>
