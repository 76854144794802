<template>
  <CCard>
    <CCardBody>
      <CDataTable
        table-filter
        items-per-page-select
        :items-per-page="20"
        :fields="fields"
        hover
        striped
        :loading="false"
        :sorter="true"
        responsive
        pagination
        :items="currentSpaces"
        align="center"
      >
        <template #area="{item}">
          <td
            v-if="item.area"
            class="py-2"
          >
            {{ item.area }}
          </td>
          <td
            v-else
            class="py-2"
          >
            {{ 0 }}
          </td>
        </template>
        <template #name="{item}">
          <td class="py-2">
            <b-avatar
              :if="item.logo"
              :src="item.logo"
              size="3rem"
            />
            <router-link :to="'/space/'+ item.id">
              {{ item.name }}
            </router-link>
          </td>
        </template>
        <template #actions="{item}">
          <td align="right">
            <SpaceQRCode
              type="button"
              :space="item"
            />
          </td>
        </template>
      </CDataTable>
    </CCardBody>
  </CCard>
</template>
<script>
import SpaceQRCode from '@/components/Spaces/SpaceQRCode';
import _ from 'lodash';

const fields = [
  {
    key: 'name',
    _style: 'width:30%;',
  },
  {
    key: 'portfolio',
    _style: 'width:20%;',
  },
  {
    key: 'country',
    _style: 'width:20%;',
  },
  {
    key: 'accessCode',
    _style: 'width:20%;',
  },
  {
    key: 'userCount',
    _style: 'width:20%;',
  },
  {
    key: 'area',
    _style: 'width:20%;',
  },
  {
    key: 'actions',
    label: '',
    sorter: false,
  },
];

export default {
  components: {
    SpaceQRCode,
  },
  props: {
    spaces: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      fields,
      details: [],
      collapseDuration: 0,
    };
  },
  computed: {
    currentSpaces() {
      return _.map(this.spaces, (space) => ({
        ...space,
        area: space.area || 0,
      }));
    },
  },
  methods: {
    toggleDetails(item) {
      this.$set(this.items[item.id], '_toggled', !item._toggled);
      this.collapseDuration = 300;
      this.$nextTick(() => {
        this.collapseDuration = 0;
      });
    },
  },
};
</script>
