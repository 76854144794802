<template>
  <CCard>
    <CCardBody>
      <CDataTable
        table-filter
        items-per-page-select
        :items-per-page="20"
        :fields="fields"
        hover
        striped
        :loading="false"
        :sorter="true"
        responsive
        pagination
        :items="computedItems"
        align="center"
      >
        <template #name="{item}">
          <td>
            <b-avatar
              id="avatar"
              v-if="item.image_url[0] !== 'f'"
              :src="item.image_url"
              size="3rem"
            />
            <b-avatar
              v-else-if="item.image_url[0] === 'f'"
              id="avatar"
              size="3rem"
            >
              <font-awesome-icon
                :icon="item.image_url.substring(3, item.image_url.length)"
                size="2x"
                class="fawesome"
              />
            </b-avatar>
            <a :href="item.web_link">
              {{ item.name }}
            </a>
          </td>
        </template>
        <template #teamTitle="{item}">
          <td>
            {{ item.teamTitle }}
          </td>
        </template>
        <template #delete="{item}">
          <td align="right">
            <CButton
              v-if="$acl.check('isAdmin')"
              class="btn btn-outline-primary mr-2"
              :to="`/links-edit/${item.id}`"
            >
              Edit
            </CButton>
            <CButton
              v-if="$acl.check('isAdmin')"
              class="btn btn-danger ml-auto mr-2"
              @click="deleteLink(item.id)"
            >
              DELETE
            </CButton>
          </td>
        </template>
      </CDataTable>
    </CCardBody>
  </CCard>
</template>
<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(fas);

export default {
  components: {
    FontAwesomeIcon,
  },
  props: {
    currentLinks: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      image: false,
      fields: [
        {
          key: 'name',
          label: 'Name',
          sorter: true,
        },
        {
          key: 'teamTitle',
          label: 'Team',
          sorter: true,
        },
        {
          key: 'delete',
          label: '',
          sorter: false,
        },
      ],
    };
  },
  computed: {
    computedItems() {
      return this.currentLinks.map((item) => ({
        ...item,
        teamTitle: item.team.title,
      }));
    },
  },
  methods: {
    async deleteLink(id) {
      await this.$store.dispatch('links/deleteLink', id);
      this.$store.dispatch('links/loadLinks');
    },
  },
};
</script>
