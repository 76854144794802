<template>
  <CCard>
    <CCardBody>
      <CDataTable
        table-filter
        items-per-page-select
        :items-per-page="20"
        :fields="fields"
        hover
        striped
        :loading="false"
        :sorter="true"
        responsive
        pagination
        :items="currentPeople"
        align="center"
      >
        <template #name="{item}">
          <td>
            <b-avatar
              :if="item.avatar"
              :src="item.avatar"
              size="3rem"
            />
            <router-link
              :to="{name: 'Person', params: {id: item.id}}"
            >
              {{ item.name }}
            </router-link>
          </td>
        </template>
        <template #update="{item}">
          <td align="right">
            <CButton
              v-if="$acl.check('isAdmin')"
              class="btn btn-outline-primary mr-2"
              :to="`/edit-person/${item.id}`"
            >
              Update
            </CButton>
            <CButton
              v-if="$acl.check('isAdmin')"
              class="btn btn-danger mr-2"
              @click="deleteTeam(item.id)"
            >
              Delete
            </CButton>
          </td>
        </template>
      </CDataTable>
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  props: {
    currentPeople: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: 'Name',
          sorter: true,
        },
        {
          key: 'email',
          label: 'Email',
          sorter: true,
        },
        {
          key: 'position',
          label: 'Positon',
          sorter: true,
        },
        {
          key: 'phone_number',
          label: 'Phone Number',
          sorter: false,
        },
        {
          key: 'update',
          label: '',
          sorter: false,
        },
      ],
    };
  },
  methods: {
    async deleteTeam(id) {
      await this.$store.dispatch('persons/deletePerson', id);
      this.$store.dispatch('persons/loadPersons');
    },
  },
};
</script>
