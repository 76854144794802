<template>
  <div
    v-if="me"
  >
    <ValidationObserver ref="validator">
      <form
        @submit.prevent="checkForm"
      >
        <CCard>
          <CCardBody>
            <CRow>
              <CCol sm="12">
                <ValidationProvider
                  v-slot="{errors, valid, validated}"
                  :rules="me.$validations.name"
                  name="Name"
                >
                  <CInput
                    v-model="me.name"
                    name="name"
                    label="Name"
                    placeholder="John Doe"
                    :is-valid="!validated ? null : valid"
                    :invalid-feedback="errors[0]"
                  />
                </ValidationProvider>
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="12">
                <ValidationProvider
                  v-slot="{errors, valid, validated}"
                  :rules="me.$validations.position"
                  name="Position"
                >
                  <CInput
                    v-model="me.position"
                    label="Position"
                    name="position"
                    placeholder="Software developer"
                    :is-valid="!validated ? null : valid"
                    :invalid-feedback="errors[0]"
                  />
                </ValidationProvider>
              </CCol>
            </CRow>
            <CRow>
              <CCol
                sm="12"
                class="pb-3"
              >
                <ValidationProvider
                  v-slot="{errors, passed, failed}"
                  :rules="me.$validations.avatar"
                  name="Avatar"
                >
                  <label
                    sm="12"
                  >
                    Upload avatar
                  </label>
                  <avatar-cropper
                    v-model="me.avatar"
                    trigger="#pick-avatar"
                    :class="{
                      'is-valid': passed,
                      'is-invalid': failed
                    }"
                  />
                  <span class="invalid invalid-feedback">{{ errors[0] }}</span>
                </ValidationProvider>
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="12">
                <div
                  role="group"
                  class="form-group"
                >
                  <label
                    for="birthDate"
                  >
                    Birth Date
                  </label>
                  <ValidationProvider
                    v-slot="{errors, passed, failed}"
                    :rules="me.$validations.birth_date"
                    name="Birth date"
                  >
                    <datepicker
                      id="birthDate"
                      v-model="birthDate"
                      name="birthDate"
                      format="yyyy-MM-dd"
                      :input-class="[
                        'form-control',
                        {
                          'is-valid': passed,
                          'is-invalid': failed
                        }
                      ]"
                      :class="{
                        'is-valid': passed,
                        'is-invalid': failed
                      }"
                      placeholder="Birth date of me..."
                    />
                    <span class="invalid invalid-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="12">
                <ValidationProvider
                  v-slot="{errors, valid, validated}"
                  :rules="me.$validations.phone_number"
                  name="Phone number"
                >
                  <CInput
                    v-model="me.phone_number"
                    type="tel"
                    label="Phone number"
                    placeholder="+420111222333"
                    :is-valid="!validated ? null : valid"
                    :invalid-feedback="errors[0]"
                  />
                </ValidationProvider>
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="12">
                <ValidationProvider
                  v-slot="{errors, valid, validated}"
                  :rules="me.$validations.email"
                  name="email"
                >
                  <CInput
                    v-model="me.email"
                    label="Email"
                    type="email"
                    placeholder="john.doe@spaceflow.com"
                    :is-valid="!validated ? null : valid"
                    :invalid-feedback="errors[0]"
                  />
                </ValidationProvider>
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="12">
                <ValidationProvider
                  v-slot="{errors, valid, validated}"
                  :rules="me.$validations.long_description"
                  name="Description"
                >
                  <TipTap
                    v-model="me.long_description"
                    label="Description"
                    placeholder="I am john doe..."
                    :is-valid="!validated ? null : valid"
                    :invalid-feedback="errors[0]"
                  />
                </ValidationProvider>
              </CCol>
            </CRow>
            <CRow>
              <CCol
                sm="12"
                align="right"
              >
                <CButton
                  color="primary"
                  type="submit"
                  class="ml-auto mb-2 mt-3"
                  :disabled="sending"
                >
                  <CSpinner
                    v-if="sending"
                    color="info"
                    size="sm"
                  />
                  Save
                </CButton>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import Me from '@/model/person/me';
import store from '@/store';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import Datepicker from 'vuejs-datepicker';
import moment from 'moment';
import AvatarCropper from '@/components/AvatarCropper';
import _ from 'lodash';
import TipTap from '@/components/Input/TipTap';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Datepicker,
    AvatarCropper,
    TipTap,
  },
  data() {
    return {
      me: new Me(),
      sending: false,
    };
  },
  computed: {
    birthDate: {
      get() {
        if (_.isNil(this.me.birth_date)) {
          return null;
        }
        return moment(this.me.birth_date)
          .format('Y-MM-DD');
      },
      set(value) {
        this.me.birth_date = moment(value).format();
      },
    },
  },
  async created() {
    await store.dispatch('persons/loadPersons');
    this.me = store.state.persons.persons[store.state.persons.me.id];
  },
  methods: {
    async checkForm() {
      try {
        this.sending = true;
        const valid = await this.$refs.validator.validate();
        if (valid) {
          await this.$store.dispatch('persons/editMyProfile', this.me);
          this.$router.push('/my-profile');
          this.$store.dispatch('alert/success', 'success');
        }
      } catch (error) {
        this.$store.dispatch('alert/error', 'error');
      } finally {
        this.sending = false;
      }
    },
  },
};
</script>
